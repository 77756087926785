import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('lightbox', function (dataSource) {
    let lastEvent = null
    return {
      lightboxInstance: null,
      open(id, e) {
        lastEvent = e
        this.lightboxInstance.loadAndOpen(id)
      },
      async init() {
        const [lightbox] = await Promise.all([
          import('photoswipe/lightbox').then(mod => mod.default),
          import('photoswipe/style.css'),
        ])

        this.lightboxInstance = new lightbox({
          dataSource,
          pswpModule: () => import('photoswipe'),
        })

        this.lightboxInstance.addFilter('thumbEl', (thumbEl, data) => {
          const selector = `[data-id="${data.id}"] img`

          if (lastEvent) {
            const el = lastEvent.target.closest(selector)
            if (el) return el
          }

          const el = this.$el.querySelector(selector)
          if (el) return el

          return thumbEl
        })
        this.lightboxInstance.addFilter('placeholderSrc', (placeholderSrc, slide) => {
          const el = this.$el.querySelector(`[data-id="${slide.data.id}"] img`)
          if (el) return el.src
          return placeholderSrc
        })

        this.lightboxInstance.init()
      },
    }
  })
})()