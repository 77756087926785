import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('shorten', function (count = 5) {
    return {
      pages: 1,
      current: 1,
      overflowing: false,
      init() {
        const root = this.$refs.target || this.$root
        const update = (current) => {
          this.overflowing = this.pages > 1 && current < this.pages

          ;[...root.children].forEach((child, index) => {
            if (index < (count * current)) {
              child.style.display = ''
            } else {
              child.style.display = 'none'
            }
          })
        }
        this.pages = Math.ceil(root.children.length / count)

        update(this.current)
        this.$watch('current', update)
      },
      nextPage() {
        if (this.current < this.pages) {
          this.current++
        }
      },
    }
  })
})()
