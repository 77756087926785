import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

import 'modules/form'
import 'modules/measure'
import 'modules/details'
import 'modules/shorten'
import 'modules/slider'
import 'modules/lightbox'
import 'modules/frame'

if (document.getElementById('matomo-opt-out')) {
  import('modules/matomo').then((mod) => mod.default())
}

function setConsent() {
  return {
    media: window.allowedCookies?.media || false,
  }
}

Alpine.store('app', {
  reloadables: [],
  allowedCookies: setConsent(),
  isAllowedCookie(type) {
    return this.allowedCookies[type] || false
  },
  menuOpen: false,
  toggleMenu() {
    this.menuOpen = !this.menuOpen
  },
  closeMenu() {
    this.menuOpen = false
  },
  reloadReloadables(cb) {
    if (this.reloadables.length > 0) {
      this.reloadables.forEach((reloadable) => {
        reloadable(cb)
      })
    }
  },
  submitForm(event) {
    // ajax submit
    fetch(event.target.action, {
      method: event.target.method,
      body: new FormData(event.target),
    })
    .then(() => {
      this.$store.app.reloadReloadables(() => {
        this.$store.app.allowedCookies = setConsent()
      })
    })
    .catch((e) => {
      console.error('Error getting form submission:', e)
      event.target.submit()
    })
  },
})

Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()