import Alpine from 'alpinejs'

/** @ref https://css-tricks.com/how-to-animate-the-details-element/ */
export default (function () {
  Alpine.data('details', function (duration = 400) {
    return {
      animation: null,
      isClosing: false,
      isExpanding: false,
      onClick() {
        this.$root.style.overflow = 'hidden'
        if (this.isClosing || !this.$root.open) {
          this.open()
        } else if (this.isExpanding || this.$root.open) {
          this.shrink()
        }
      },
      onAnimationFinish(open) {
        this.$root.open = open
        this.animation = null
        this.isClosing = false
        this.isExpanding = false
        this.$root.style.height = this.$root.style.overflow = ''
      },
      shrink() {
        // Set the element as "being closed"
        this.isClosing = true

        // Store the current height of the element
        const startHeight = `${this.$root.offsetHeight}px`
        // Calculate the height of the summary
        const endHeight = `${this.$refs.summary.offsetHeight}px`

        // If there is already an animation running
        if (this.animation) {
          // Cancel the current animation
          this.animation.cancel()
        }

        // Start a WAAPI animation
        this.animation = this.$root.animate(
          {
            // Set the keyframes from the startHeight to endHeight
            height: [startHeight, endHeight],
          },
          {
            duration,
            easing: 'ease-out',
          }
        )

        // When the animation is complete, call onAnimationFinish()
        this.animation.onfinish = () => this.onAnimationFinish(false)
        // If the animation is cancelled, isClosing variable is set to false
        this.animation.oncancel = () => (this.isClosing = false)
      },

      open() {
        // Apply a fixed height on the element
        this.$root.style.height = `${this.$root.offsetHeight}px`
        // Force the [open] attribute on the details element
        this.$root.open = true
        // Wait for the next frame to call the expand function
        window.requestAnimationFrame(() => this.expand())
      },

      expand() {
        // Set the element as "being expanding"
        this.isExpanding = true
        // Get the current fixed height of the element
        const startHeight = `${this.$root.offsetHeight}px`
        // Calculate the open height of the element (summary height + content height)
        const endHeight = `${
          this.$refs.summary.offsetHeight + this.$refs.content.offsetHeight
        }px`

        // If there is already an animation running
        if (this.animation) {
          // Cancel the current animation
          this.animation.cancel()
        }

        // Start a WAAPI animation
        this.animation = this.$root.animate(
          {
            // Set the keyframes from the startHeight to endHeight
            height: [startHeight, endHeight],
          },
          {
            duration: 400,
            easing: 'ease-out',
          }
        )
        // When the animation is complete, call onAnimationFinish()
        this.animation.onfinish = () => this.onAnimationFinish(true)
        // If the animation is cancelled, isExpanding variable is set to false
        this.animation.oncancel = () => (this.isExpanding = false)
      },
    }
  })
})()
