import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('frame', function (path, element) {
    const abortController = new AbortController()

    return {
      loading: false,
      content: null,
      init() {
        const url = new URL(path, window.location)
        if (
          process.env.NODE_ENV !== 'development' &&
          !(window.location.hostname === url.hostname || !url.hostname.length)
        ) {
          return
        }
        const signal = abortController.signal
        const load = (cb = () => {}) => {
          if (this.loading) {
            abortController.abort()
            this.loading = false
          }

          this.loading = true
          fetch(url, { method: 'POST', signal })
            .then((response) => response.text())
            .then((html) => {
              this.content = html && html.trim().length > 0 ? html : null
              if (element) {
                const node = document.createElement(element)
                node.innerHTML = this.content
                this.$root.appendChild(node)
              }
            })
            .catch((error) => {
              console.error('Error getting frame content:', error)
            })
            .finally(() => {
              this.loading = false
              cb()
            })
        }

        Alpine.store('app').reloadables.push(load)
        load()
      },
    }
  })
})()
